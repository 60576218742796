import { getShuffledArr } from "../components/play-test/utils";

const NUM_ORDER = ["A", "2", "3", "4", "5", "6", "7", "8", "9", "T", "J", "Q", "K"];

const CARDS = NUM_ORDER.reduce((cards, num, index) => {
  ["C", "S"].forEach((suit) => {
    cards.push({
      id: num + suit,
      front: num + suit,
      back: "2B",
      suit,
      color: "black",
      direction: "down",
      num,
      order: index,
    });
  });

  ["H", "D"].forEach((suit) => {
    if (suit === "D" && num === "Q") return;

    cards.push({
      id: num + suit,
      front: num + suit,
      back: "2B",
      suit,
      color: "red",
      direction: "down",
      num,
      order: index,
    });
  });

  return cards;
}, []);

const PADDING = 20;
const WIDTH = 125;
const HEIGHT = 175;

const getX = (pos) => {
  return pos * (WIDTH + PADDING) + PADDING;
};

const getY = (pos) => {
  return pos * (HEIGHT + PADDING) + PADDING;
};

const OLD_MAID = {
  minPlayers: 2,
  maxPlayers: 6,
  cards: CARDS,
  stacks: [
    {
      stackDirection: "horizontalFaceUp",
      id: "discard",
      x: getX(0),
      y: getY(0),
    },
    {
      stackDirection: "horizontalPlayerUp",
      id: "hand",
      x: getX(0),
      y: getY(1),
    },
  ],
  gameSetup: (config) => {
    const cards = getShuffledArr(config.cards);

    for (let i = 0; i < 10; i++) {
      config.stacks[0].cards.push(cards.pop());
      config.stacks[1].cards.push(cards.pop());
    }
  },
};

export default OLD_MAID;
