import React, { useContext } from "react";

import ConfigContext from "./config.context";
import StateContext from "./state.context";

import { Card } from "./card";

const verticalStack = (card, i, array) => {
  return {
    style: {
      position: "absolute",
      top: `${i * -2}px`,
      left: 0,
    },
  };
};

const cascadeStack = (card, i, array) => {
  let top = 0;

  for (let j = 0; j < i; j++) {
    if (array[j].direction === "down") {
      top += 2;
    } else {
      top += 40;
    }
  }

  return {
    style: {
      position: "absolute",
      top: `${top}px`,
      left: 0,
    },
  };
};

const horizontalFaceUpStack = (card, i, array) => {
  return {
    style: {
      position: "absolute",
      top: 0,
      left: `${i * 25 - (array.length / 2) * 25}px`,
    },
    direction: "up",
  };
};

const horizontalPlayerUpStack = (card, i, array) => {
  return {
    style: {
      position: "absolute",
      top: 0,
      left: `${i * 25 - (array.length / 2) * 25}px`,
    },
    direction: "up",
  };
};

const STACK_MAP = {
  cascade: cascadeStack,
  vertical: verticalStack,
  horizontalFaceUp: horizontalFaceUpStack,
  horizontalPlayerUp: horizontalPlayerUpStack,
};

export const Stack = (props) => {
  const { stack } = props;

  const config = useContext(ConfigContext);
  const [state, dispatch] = useContext(StateContext);
  const stackConfig = config.stacks.find((s) => s.id === stack.id);
  const canClick = stackConfig.canClick && stackConfig.canClick(state, stack);

  let cursor;
  if (canClick) {
    cursor = "pointer";
  } else {
    cursor = "default";
  }

  const onClick = (e) => {
    if (canClick) {
      stackConfig.onClick(state, stack, null, dispatch);
    }
  };

  const canDrop = !!state.draggingCards && !!stackConfig.canDrop && stackConfig.canDrop(state, stack);
  const stacker = STACK_MAP[stackConfig.stackDirection] ? STACK_MAP[stackConfig.stackDirection] : verticalStack;

  const onDrop = () => {
    dispatch([
      {
        type: "STOP_DRAGGING",
      },
      ...state.draggingCards.map((card) => ({
        type: "MOVE_CARD",
        stackID: state.draggingStack.id,
        cardID: card.id,
        toStackID: stack.id,
      })),
    ]);
  };

  return (
    <div
      style={{
        position: "absolute",
        top: `${stack.y}px`,
        left: `${stack.x}px`,
      }}
    >
      {(stack.cards || []).map((card, i, array) => (
        <Card key={`${stack.id}-${i}`} stack={stack} stackConfig={stackConfig} card={card} {...stacker(card, i, array)} />
      ))}
      <div
        style={{
          border: "1px solid #666666",
          borderRadius: "5px",
          width: "125px",
          height: "175px",
          cursor,
        }}
        onDragOver={(e) => {
          if (canDrop) e.preventDefault();
        }}
        onDrop={onDrop}
        onClick={onClick}
      />
    </div>
  );
};
