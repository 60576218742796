import React, { useContext } from "react";
import StateContext from "./state.context";

export const Card = (props) => {
  const { card, stack, style, stackConfig, direction } = props;

  const [state, dispatch] = useContext(StateContext);

  const isDragging = state.draggingCards && state.draggingCards.find((c) => c.id === card.id);

  const onDragStart = (e) => {
    dispatch({
      type: "START_DRAGGING",
      cards: stack.cards.slice(stack.cards.findIndex((c) => c.id === card.id)),
      stack,
    });
    e.dataTransfer.effectAllowed = "move";
  };

  const onDragEnd = () => {
    dispatch({
      type: "STOP_DRAGGING",
    });
  };

  const onDrop = () => {
    dispatch([
      {
        type: "STOP_DRAGGING",
      },
      ...state.draggingCards.map((card) => ({
        type: "MOVE_CARD",
        stackID: state.draggingStack.id,
        cardID: card.id,
        toStackID: stack.id,
      })),
    ]);
  };

  const canClick = stackConfig.canClick && stackConfig.canClick(state, stack, card);
  const canDoubleClick = stackConfig.canDoubleClick && stackConfig.canDoubleClick(state, stack, card);
  const canDrag = stackConfig.canDrag && stackConfig.canDrag(state, stack, card);
  const canDrop = !!state.draggingCards && !!stackConfig.canDrop && stackConfig.canDrop(state, stack, card);

  const onClick = (e) => {
    if (canClick) {
      stackConfig.onClick(state, stack, card, dispatch);
    }
  };

  const onDoubleClick = (e) => {
    if (canDoubleClick) {
      stackConfig.onDoubleClick(state, stack, card, dispatch);
    }
  }

  let cursor;
  if (canClick || canDoubleClick) {
    cursor = "pointer";
  } else if (canDrag) {
    cursor = "grab";
  } else {
    cursor = "default";
  }

  return (
    <div
      onDoubleClick={onDoubleClick}
      onClick={onClick}
      draggable={canDrag}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragOver={(e) => {
        if (canDrop) e.preventDefault();
      }}
      onDrop={onDrop}
      className={`flip-card face-${direction || card.direction}`}
      style={{
        ...style,
        transition: "0.01s",
        transform: isDragging ? "translateX(-9999px)" : null,
        cursor,
      }}
    >
      <div className="flip-card-inner">
        <img className="flip-card-back" alt="" src={`/assets/${card.back}.svg`} draggable={false} />
        <img className="flip-card-front" alt="" src={`/assets/${card.front}.svg`} draggable={false} />
      </div>
    </div>
  );
};
