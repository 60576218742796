import React, { useState, useReducer, useEffect } from "react";
import StateContext from "./state.context";
import ConfigContext from "./config.context";
import { useParams, useNavigate } from "react-router-dom";
import { PageHeader, Spin, Result, Button, Typography } from "antd";
import { Fireworks } from "fireworks-js/dist/react";

import { Stack } from "./stack";
import REDUCER from "./state.reducer";

import { GAME_MAP } from "../../game-configs";
import { idToTitlecase } from "./utils";

export const Game = () => {
  const { gameId } = useParams();
  const [config, setConfig] = useState({});
  const [missing, setMissing] = useState(false);
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(REDUCER, { _status: "unloaded" });

  useEffect(() => {
    dispatch({ type: "RESET_GAME" });

    if (!GAME_MAP[gameId]) {
      // Do something if the game doesn't exist...
      setMissing(true);
      return;
    }

    let config = GAME_MAP[gameId];

    setConfig(config);
    setMissing(false);

    dispatch({
      type: "INIT_CONFIG",
      config,
    });
  }, [gameId]);

  return (
    <StateContext.Provider value={[state, dispatch]}>
      <ConfigContext.Provider value={config}>
        <PageHeader className="site-page-header" onBack={() => navigate("/")} title={config.name || "Playability"} />

        {missing && (
          <Result
            style={{ marginTop: "50px" }}
            title={`Unable to find game ${idToTitlecase(gameId)}`}
            extra={
              <Button type="primary" key="console" onClick={() => navigate("/")}>
                Go Back
              </Button>
            }
          />
        )}

        {!missing && state._status === "unloaded" && (
          <div style={{ display: "flex", justifyContent: "center", height: "100%", alignItems: "center", flexGrow: 1 }}>
            <Spin size="large" tip="Loading..." />
          </div>
        )}

        {!missing && state._status === "awaiting_players" && (
          <div style={{ display: "flex", justifyContent: "center", height: "100%", alignItems: "center", flexGrow: 1 }}>
            Awaiting players
          </div>
        )}

        {!missing && state._status === "loaded" && (
          <div
            style={{
              position: "relative",
              marginTop: "50px",
              width: `${config.width}px`,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {state.stacks.map((stack) => (
              <Stack key={stack.id} stack={stack} />
            ))}
          </div>
        )}

        {!missing && (
          <div
            style={{
              position: "fixed",
              top: "50px",
              width: "100vw",
              height: "calc(100vh - 50px)",
              opacity: state._winner !== true ? 0 : 1,
              pointerEvents: state._winner !== true ? "none" : "auto",
              transition: "opacity 3s",
            }}
          >
            {state._winner === true && <Fireworks style={{ width: "100%", height: "100%" }} />}
            <div
              style={{
                position: "fixed",
                width: "100%",
                top: "50%",
                left: 0,
                height: "100px",
                background: "#1DA57A",
                color: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography.Title style={{ color: "#fff" }}>You Win!</Typography.Title>
            </div>
          </div>
        )}
      </ConfigContext.Provider>
    </StateContext.Provider>
  );
};
