import solitaire from "./solitaire";
import spiderSolitaireOneSuit from "./spider-solitaire-one-suit";
import spiderSolitaireTwoSuit from "./spider-solitaire-two-suit";
import spiderSolitaireFourSuit from "./spider-solitaire-four-suit";
import freecell from "./freecell";
import oldMaid from "./old-maid";

export const GAME_MAP = {
  solitaire,
  freecell,
  "spider-solitaire-one-suit": spiderSolitaireOneSuit,
  "spider-solitaire-two-suit": spiderSolitaireTwoSuit,
  "spider-solitaire-four-suit": spiderSolitaireFourSuit,
  "old-maid": oldMaid,
};
