/**
 * Create a copy of the array, shuffle it, and return the new array
 *
 * @param {array} arr
 * @returns array
 */
export const getShuffledArr = (arr) => {
  return deepClone(arr).map((_, i, arrCopy) => {
    var rand = i + Math.floor(Math.random() * (arrCopy.length - i));
    [arrCopy[rand], arrCopy[i]] = [arrCopy[i], arrCopy[rand]];
    return arrCopy[i];
  });
};

/**
 * Simple function for creating a deep clone of a nested simple JavaScript object.
 *
 * @param obj
 * @returns object
 */
export const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

export const idToTitlecase = (id) => {
  return id
    .split("-")
    .map((word) => `${word[0].toUpperCase()}${word.substr(1)}`)
    .join(" ");
};
