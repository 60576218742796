import React, { useEffect, useState, useRef } from "react";
import { PageHeader, Input, Button } from "antd";
import { useNavigate } from "react-router-dom";

export const ChatSandbox = () => {
  const navigate = useNavigate();
  const ws = useRef();
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    ws.current = new WebSocket("ws://localhost:8000/ws/chat/lobby/");
    ws.current.onmessage = function (event) {
      event = JSON.parse(event.data);
      console.log(event);
      setMessages((messages) => [...messages, event]);
    };
    return () => ws.current.close();
  }, []);

  const sendMessage = () => {
    ws.current.send(
      JSON.stringify({
        name,
        message,
      })
    );
    setMessage("");
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh", alignItems: "center" }}>
      <PageHeader style={{ width: "100%" }} className="site-page-header" onBack={() => navigate("/")} title={"Chat Sandbox"} />
      <div style={{ margin: "40px", flexGrow: 1, width: "500px", display: "flex", flexDirection: "column" }}>
        <div style={{ flex: "1 0 0", overflowY: "auto", border: "1px solid #d4d4d4" }}>
          {messages.map((message, i) => (
            <p key={i}>
              <strong>{message.name}</strong>
              <br />
              {message.message}
            </p>
          ))}
        </div>
        {!name ? (
          <div>
            <Input.Search placeholder="Enter your name" enterButton="Enter" size="large" onSearch={setName} />
          </div>
        ) : (
          <div style={{ textAlign: "right" }}>
            <Input.TextArea style={{ width: "100%" }} value={message} onChange={(e) => setMessage(e.target.value)} />
            <br />
            <Button type="primary" onClick={sendMessage}>
              Send
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
