import React, { useRef, useEffect, useState } from "react";
import { Row, Col, Button, Typography, PageHeader } from "antd";
import { useNavigate } from "react-router-dom";

const { Title, Paragraph } = Typography;

const players = [1, 2, 3, 4, 5, 6, 7, 8];

export const Sandbox = () => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    console.log(ref.current);
    setHeight(ref.current.clientHeight);
    setWidth(ref.current.clientWidth);
    const onResize = () => {
      setHeight(ref.current.clientHeight);
      setWidth(ref.current.clientWidth);
    };

    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <PageHeader className="site-page-header" onBack={() => navigate("/")} title="Sandbox" />
      <div ref={ref} style={{ margin: "40px", flexGrow: 1, transform: "origin(50%, 50%)", position: "relative" }}>
        {players.map((_, i, arr) => (
          <div
            key={i}
            style={{
              background: "#000000",
              width: "20px",
              height: "20px",
              position: "absolute",
              transformOrigin: `50% -${height / 2}px`,
              transform: `translate(${width / 2}px, ${height}px) rotate(${(-360 / arr.length) * i}deg)`,
            }}
          >
            &nbsp;
          </div>
        ))}
      </div>
    </div>
  );
};
