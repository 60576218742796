import { deepClone } from "./utils";

/*

 1) Init Config
    Load the config into memory and figure out how many players are needed

 2) Await Players
    Await for the maximum amount of players

*/

let currentConfig;

const INIT_CONFIG = (state, action) => {
  const config = deepClone(action.config);

  console.log(action);

  config.stacks.forEach((stack) => (stack.cards = []));
  action.config.gameSetup(config);
  currentConfig = action.config;

  return {
    ...config,
    _status: "loaded",
  };
};

const START_GAME = (state, action) => {
  const newState = deepClone(state);

  newState.config.stacks.forEach((stack) => (stack.cards = []));
  newState.config.gameSetup(newState.config);
  newState._status = "loaded";

  return newState;
};

const RESET_GAME = (state, action) => {
  return {
    _status: "unloaded",
  };
};

const UPDATE_STATE = (state, action) => {
  // TODO maybe do deep compare?
  const newState = deepClone(state);
  return {
    ...newState,
    ...action.values,
  };
};

const FLIP_CARD = (state, action) => {
  const { stackID, cardID } = action;

  const newState = deepClone(state);
  const stack = newState.stacks.find((stack) => stack.id === stackID);
  const card = stack.cards.find((card) => card.id === cardID);
  card.direction = card.direction === "up" ? "down" : "up";

  return newState;
};

const MOVE_CARD = (state, action) => {
  const { stackID, cardID, toStackID } = action;

  const newState = deepClone(state);
  const stack = newState.stacks.find((stack) => stack.id === stackID);
  const card = stack.cards.splice(
    stack.cards.findIndex((card) => card.id === cardID),
    1
  )[0];
  const toStack = newState.stacks.find((stack) => stack.id === toStackID);
  toStack.cards.push(card);
  return newState;
};

const FLIP_STACK = (state, action) => {
  const { stackID } = action;

  const newState = deepClone(state);
  const stack = newState.stacks.find((stack) => stack.id === stackID);
  stack.cards.reverse();
  stack.cards.forEach((card) => {
    card.direction = card.direction === "up" ? "down" : "up";
  });

  return newState;
};

const MOVE_STACK = (state, action) => {
  const { stackID, toStackID } = action;

  const newState = deepClone(state);
  const stack = newState.stacks.find((stack) => stack.id === stackID);
  const toStack = newState.stacks.find((stack) => stack.id === toStackID);
  toStack.cards = stack.cards;
  stack.cards = [];
  return newState;
};

const START_DRAGGING = (state, action) => {
  const { cards, stack } = action;

  const newState = deepClone(state);
  newState.draggingCards = cards;
  newState.draggingStack = stack;

  return newState;
};

const STOP_DRAGGING = (state, action) => {
  const newState = deepClone(state);
  delete newState.draggingCards;
  delete newState.draggingStack;
  return newState;
};

const AFTER_ACTION = (state) => {
  if (currentConfig && currentConfig.onActionComplete) {
    return currentConfig.onActionComplete(state);
  }
};

const ACTIONS = {
  INIT_CONFIG,
  FLIP_CARD,
  MOVE_CARD,
  FLIP_STACK,
  MOVE_STACK,
  START_DRAGGING,
  STOP_DRAGGING,
  RESET_GAME,
  AFTER_ACTION,
  UPDATE_STATE,
};

const REDUCE_ACTIONS = (state, action) => {
  if (ACTIONS[action.type]) {
    return ACTIONS[action.type](state, action);
  }

  return state;
};

const REDUCER = (state, actions) => {
  if (actions.constructor.name !== "Array") {
    actions = [actions];
  }

  state = actions.reduce(REDUCE_ACTIONS, state);

  const extraActions = AFTER_ACTION(state);

  if (extraActions) {
    state = extraActions.reduce(REDUCE_ACTIONS, state);
  }

  return state;
};

export default REDUCER;
