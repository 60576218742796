import { getShuffledArr } from "../components/play-test/utils";

const NUM_ORDER = ["A", "2", "3", "4", "5", "6", "7", "8", "9", "T", "J", "Q", "K"];

const CARDS = NUM_ORDER.reduce((cards, num, index) => {
  ["C", "S"].forEach((suit) => {
    cards.push({
      id: num + suit,
      front: num + suit,
      back: "2B",
      suit,
      color: "black",
      direction: "down",
      num,
      order: index,
    });
  });

  ["H", "D"].forEach((suit) => {
    cards.push({
      id: num + suit,
      front: num + suit,
      back: "2B",
      suit,
      color: "red",
      direction: "down",
      num,
      order: index,
    });
  });

  return cards;
}, []);

const PADDING = 20;
const WIDTH = 125;
const HEIGHT = 175;

const getX = (pos) => {
  return pos * (WIDTH + PADDING) + PADDING;
};

const getY = (pos) => {
  return pos * (HEIGHT + PADDING) + PADDING;
};

const ACES_STACK = {
  type: "aces",
  canDrag: (game, stack, card) => {
    return card && stack.cards.findIndex((c) => c.id === card.id) + 1 === stack.cards.length;
  },
  canDrop: (game, stack, card) => {
    if (!card) {
      return game.draggingCards[0].num === "A";
    }

    return (
      stack.cards.findIndex((c) => c.id === card.id) + 1 === stack.cards.length &&
      card.order + 1 === game.draggingCards[0].order &&
      card.suit === game.draggingCards[0].suit
    );
  },
};

const doubleClickMixin = {
  canDoubleClick: (game, stack, card) => {
    if (!card || card.direction === "down") return false;
    if (card.num === "A") return true;

    return game.stacks
      .filter((s) => s.type === "aces")
      .some((stack) => {
        if (!stack.cards.length) return false;
        const topCard = stack.cards[stack.cards.length - 1];
        return topCard.suit === card.suit && card.order - 1 === topCard.order;
      });
  },
  onDoubleClick: (game, stack, card, dispatch) => {
    const aceStack = game.stacks
      .filter((s) => s.type === "aces")
      .find((stack) => {
        if (stack.cards.length === 0) return true;
        const topCard = stack.cards[stack.cards.length - 1];
        return topCard.suit === card.suit && card.order - 1 === topCard.order;
      });

    if (aceStack) {
      dispatch({
        type: "MOVE_CARD",
        stackID: stack.id,
        cardID: card.id,
        toStackID: aceStack.id,
      });
    }
  },
};

const DISCARD_STACK = {
  canDrag: () => true,
  ...doubleClickMixin,
};

const PLAY_STACK = {
  type: "play",
  stackDirection: "cascade",
  canDrag: (game, stack, card) => {
    return card && card.direction === "up";
  },
  canClick: (game, stack, card) => {
    return card && stack.cards.findIndex((c) => c.id === card.id) + 1 === stack.cards.length && card.direction === "down";
  },
  onClick: (game, stack, card, dispatch) => {
    dispatch({
      type: "FLIP_CARD",
      stackID: stack.id,
      cardID: card.id,
    });
  },
  canDrop: (game, stack, card) => {
    if (!card) {
      return game.draggingCards[0].num === "K";
    }

    return (
      stack.cards.findIndex((c) => c.id === card.id) + 1 === stack.cards.length &&
      card.order - 1 === game.draggingCards[0].order &&
      card.color !== game.draggingCards[0].color
    );
  },
  ...doubleClickMixin,
};

const DECK_STACK = {
  canClick: (game, stack, card) => {
    // Only an empty pile or the top card can be clicked
    return (!card && stack.cards.length === 0) || (card && stack.cards.findIndex((c) => c.id === card.id) + 1 === stack.cards.length);
  },
  onClick: (game, stack, card, dispatch) => {
    if (!card) {
      // If there are no cards, take all cards from discard pile and place them here
      dispatch([
        {
          type: "FLIP_STACK",
          stackID: "discard",
        },
        {
          type: "MOVE_STACK",
          stackID: "discard",
          toStackID: stack.id,
        },
      ]);
    } else {
      // if there is a card, move it to the discard pile
      dispatch([
        {
          type: "FLIP_CARD",
          stackID: stack.id,
          cardID: card.id,
        },
        {
          type: "MOVE_CARD",
          stackID: stack.id,
          cardID: card.id,
          toStackID: "discard",
        },
      ]);
    }
  },
};

const SOLITAIRE = {
  name: "Solitaire",
  width: 1035,
  gameSetup: (config) => {
    const cards = getShuffledArr(config.cards);
    for (let i = 1; i <= 7; i++) {
      const stack = config.stacks.find((s) => s.id === `play${i}`);
      stack.cards = [];

      let j = 0;
      for (j = 0; j < i; j++) {
        stack.cards.push(cards.pop());
      }

      stack.cards[j - 1].direction = "up";
    }

    const deck = config.stacks.find((s) => s.id === "deck");
    deck.cards = cards;
  },
  onActionComplete: (state) => {
    const extraActions = [];

    if (state.stacks) {
      const isWinner = state.stacks.every((stack) => {
        if (stack.cards) {
          return stack.cards.every((card) => card.direction === "up");
        }
        return true;
      });

      if (isWinner) {
        return [
          {
            type: "UPDATE_STATE",
            values: {
              _winner: true,
            },
          },
        ];
      }
    }

    return extraActions;
  },
  decks: [
    {
      id: "standard",
      width: WIDTH,
      height: HEIGHT,
    },
  ],
  cards: CARDS,
  stacks: [
    {
      id: "aces1",
      x: getX(0),
      y: getY(0),
      ...ACES_STACK,
    },
    {
      id: "aces2",
      x: getX(1),
      y: getY(0),
      ...ACES_STACK,
    },
    {
      id: "aces3",
      x: getX(2),
      y: getY(0),
      ...ACES_STACK,
    },
    {
      id: "aces4",
      x: getX(3),
      y: getY(0),
      ...ACES_STACK,
    },
    {
      id: "discard",
      x: getX(5),
      y: getY(0),
      ...DISCARD_STACK,
    },
    {
      id: "deck",
      x: getX(6),
      y: getY(0),
      ...DECK_STACK,
    },
    {
      id: "play1",
      x: getX(0),
      y: getY(1),
      ...PLAY_STACK,
    },
    {
      id: "play2",
      x: getX(1),
      y: getY(1),
      ...PLAY_STACK,
    },
    {
      id: "play3",
      x: getX(2),
      y: getY(1),
      ...PLAY_STACK,
    },
    {
      id: "play4",
      x: getX(3),
      y: getY(1),
      ...PLAY_STACK,
    },
    {
      id: "play5",
      x: getX(4),
      y: getY(1),
      ...PLAY_STACK,
    },
    {
      id: "play6",
      x: getX(5),
      y: getY(1),
      ...PLAY_STACK,
    },
    {
      id: "play7",
      x: getX(6),
      y: getY(1),
      ...PLAY_STACK,
    },
  ],
};

export default SOLITAIRE;
