import { getShuffledArr } from "../components/play-test/utils";

const NUM_ORDER = ["A", "2", "3", "4", "5", "6", "7", "8", "9", "T", "J", "Q", "K"];

const CARDS = NUM_ORDER.reduce((cards, num, index) => {
  for (let i = 0; i < 2; i++) {
    ["S", "C"].forEach((suit) => {
      cards.push({
        id: `${i}` + num + suit,
        front: num + suit,
        back: "2B",
        suit,
        color: "black",
        direction: "down",
        num,
        order: index,
      });
    });
    ["H", "D"].forEach((suit) => {
      cards.push({
        id: `${i}` + num + suit,
        front: num + suit,
        back: "2B",
        suit,
        color: "red",
        direction: "down",
        num,
        order: index,
      });
    });
  }
  return cards;
}, []);

const PADDING = 20;
const WIDTH = 125;
const HEIGHT = 175;

const getX = (pos) => {
  return pos * (WIDTH + PADDING) + PADDING;
};

const getY = (pos) => {
  return pos * (HEIGHT + PADDING) + PADDING;
};

const ACES_STACK = {
  canDrop: (game, stack, card) => {
    return !card && game.draggingCards.length === 13;
  },
};

const PLAY_STACK = {
  stackDirection: "cascade",
  canDrag: (game, stack, card) => {
    const cardIndex = stack.cards.findIndex((c) => c.id === card.id);
    return (
      card &&
      card.direction === "up" &&
      stack.cards.slice(cardIndex).every((c, i, a) => c.suit === card.suit && (i === 0 || c.num + 1 === a[i - 1].num))
    );
  },
  canClick: (game, stack, card) => {
    return card && stack.cards.findIndex((c) => c.id === card.id) + 1 === stack.cards.length && card.direction === "down";
  },
  onClick: (game, stack, card, dispatch) => {
    dispatch({
      type: "FLIP_CARD",
      stackID: stack.id,
      cardID: card.id,
    });
  },
  canDrop: (game, stack, card) => {
    if (!card) {
      return true;
    }

    return stack.cards.findIndex((c) => c.id === card.id) + 1 === stack.cards.length && card.order - 1 === game.draggingCards[0].order;
  },
};

const DECK_STACK = {
  canClick: (game, stack, card) => {
    return stack.cards.length && game.stacks.filter((stack) => stack.type === "play").every((stack) => stack.cards.length);
  },
  onClick: (game, stack, card, dispatch) => {
    const actions = [];

    for (let i = 1; i <= 10; i++) {
      const cardID = stack.cards[stack.cards.length - i].id;
      actions.push({
        type: "FLIP_CARD",
        stackID: stack.id,
        cardID,
      });
      actions.push({
        type: "MOVE_CARD",
        stackID: stack.id,
        toStackID: `play${i}`,
        cardID,
      });
    }

    dispatch(actions);
  },
};

const SOLITAIRE = {
  name: "Solitaire",
  width: 1470,
  gameSetup: (config) => {
    const cards = getShuffledArr(config.cards);
    for (let i = 1; i <= 10; i++) {
      const stack = config.stacks.find((s) => s.id === `play${i}`);
      stack.cards = [];

      let j = 0;
      for (j = 0; j < (i <= 4 ? 6 : 5); j++) {
        stack.cards.push(cards.pop());
      }

      stack.cards[j - 1].direction = "up";
    }

    const deck = config.stacks.find((s) => s.id === "deck");
    deck.cards = cards;
  },
  decks: [
    {
      id: "standard",
      width: WIDTH,
      height: HEIGHT,
    },
  ],
  cards: CARDS,
  stacks: [
    {
      id: "aces1",
      type: "aces",
      x: getX(0),
      y: getY(0),
      ...ACES_STACK,
    },
    {
      id: "aces2",
      type: "aces",
      x: getX(1),
      y: getY(0),
      ...ACES_STACK,
    },
    {
      id: "aces3",
      type: "aces",
      x: getX(2),
      y: getY(0),
      ...ACES_STACK,
    },
    {
      id: "aces4",
      type: "aces",
      x: getX(3),
      y: getY(0),
      ...ACES_STACK,
    },
    {
      id: "aces5",
      type: "aces",
      x: getX(4),
      y: getY(0),
      ...ACES_STACK,
    },
    {
      id: "aces6",
      type: "aces",
      x: getX(5),
      y: getY(0),
      ...ACES_STACK,
    },
    {
      id: "aces7",
      type: "aces",
      x: getX(6),
      y: getY(0),
      ...ACES_STACK,
    },
    {
      id: "aces8",
      type: "aces",
      x: getX(7),
      y: getY(0),
      ...ACES_STACK,
    },
    {
      id: "deck",
      x: getX(9),
      y: getY(0),
      ...DECK_STACK,
    },
    {
      id: "play1",
      type: "play",
      x: getX(0),
      y: getY(1),
      ...PLAY_STACK,
    },
    {
      id: "play2",
      type: "play",
      x: getX(1),
      y: getY(1),
      ...PLAY_STACK,
    },
    {
      id: "play3",
      type: "play",
      x: getX(2),
      y: getY(1),
      ...PLAY_STACK,
    },
    {
      id: "play4",
      type: "play",
      x: getX(3),
      y: getY(1),
      ...PLAY_STACK,
    },
    {
      id: "play5",
      type: "play",
      x: getX(4),
      y: getY(1),
      ...PLAY_STACK,
    },
    {
      id: "play6",
      type: "play",
      x: getX(5),
      y: getY(1),
      ...PLAY_STACK,
    },
    {
      id: "play7",
      type: "play",
      x: getX(6),
      y: getY(1),
      ...PLAY_STACK,
    },
    {
      id: "play8",
      type: "play",
      x: getX(7),
      y: getY(1),
      ...PLAY_STACK,
    },
    {
      id: "play9",
      type: "play",
      x: getX(8),
      y: getY(1),
      ...PLAY_STACK,
    },
    {
      id: "play10",
      type: "play",
      x: getX(9),
      y: getY(1),
      ...PLAY_STACK,
    },
  ],
};

export default SOLITAIRE;
