import "./app.less";
import { MainMenu, Game, Sandbox, ChatSandbox } from "./components";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<MainMenu />} />
          <Route path="play/:gameId">
            <Route index element={<Game />} />
          </Route>
          <Route path="chat">
            <Route index element={<ChatSandbox />} />
          </Route>
          <Route path="sandbox">
            <Route index element={<Sandbox />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
