import { Row, Col, Button, Typography } from "antd";
import { Link } from "react-router-dom";

const { Title, Paragraph } = Typography;

export const MainMenu = () => {
  return (
    <Row justify="center" style={{ marginTop: "30px" }}>
      <Col>
        <Title>Playabilty</Title>

        <Paragraph>
          Welcome to Plyability. We're working on a low code/no code solution to designing card games (and maybe boardgames). Try some of
          our games below:
        </Paragraph>

        <Row gutter={[16, 16]} style={{ marginTop: "30px" }}>
          <Col>
            <Link to="/play/solitaire">
              <Button size="large" type="primary">
                Solitaire - One Card
              </Button>
            </Link>
          </Col>
          <Col>
            <Link to="/play/freecell">
              <Button size="large" type="primary">
                Free Cell
              </Button>
            </Link>
          </Col>
          <Col>
            <Link to="/play/spider-solitaire-one-suit">
              <Button size="large" type="primary">
                Spider Solitaire - One Suit
              </Button>
            </Link>
          </Col>
          <Col>
            <Link to="/play/spider-solitaire-two-suit">
              <Button size="large" type="primary">
                Spider Solitaire - Two Suits
              </Button>
            </Link>
          </Col>
          <Col>
            <Link to="/play/spider-solitaire-four-suit">
              <Button size="large" type="primary">
                Spider Solitaire - Four Suits
              </Button>
            </Link>
          </Col>
          <Col>
            <Link to="/play/old-maid">
              <Button size="large" type="primary">
                Old Maid
              </Button>
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
