import { getShuffledArr } from "../components/play-test/utils";

const NUM_ORDER = ["A", "2", "3", "4", "5", "6", "7", "8", "9", "T", "J", "Q", "K"];

const CARDS = NUM_ORDER.reduce((cards, num, index) => {
  ["C", "S"].forEach((suit) => {
    cards.push({
      id: num + suit,
      front: num + suit,
      back: "2B",
      suit,
      color: "black",
      direction: "down",
      num,
      order: index,
    });
  });

  ["H", "D"].forEach((suit) => {
    cards.push({
      id: num + suit,
      front: num + suit,
      back: "2B",
      suit,
      color: "red",
      direction: "down",
      num,
      order: index,
    });
  });

  return cards;
}, []);

const PADDING = 20;
const WIDTH = 125;
const HEIGHT = 175;

const getX = (pos) => {
  return pos * (WIDTH + PADDING) + PADDING;
};

const getY = (pos) => {
  return pos * (HEIGHT + PADDING) + PADDING;
};

const ACES_STACK = {
  canDrag: (game, stack, card) => {
    return card && stack.cards.findIndex((c) => c.id === card.id) + 1 === stack.cards.length;
  },
  canDrop: (game, stack, card) => {
    if (!card) {
      return game.draggingCards[0].num === "A";
    }

    return (
      stack.cards.findIndex((c) => c.id === card.id) + 1 === stack.cards.length &&
      card.order + 1 === game.draggingCards[0].order &&
      card.suit === game.draggingCards[0].suit
    );
  },
};

const FREE_STACK = {
  canDrag: (game, stack, card) => !!card,
  canDrop: (game, stack, card) => !card,
};

const PLAY_STACK = {
  stackDirection: "cascade",
  canDrag: (game, stack, card) => stack.cards.findIndex((c) => c.id === card.id) + 1 === stack.cards.length,
  canDrop: (game, stack, card) => {
    if (!card) {
      return true;
    }

    return (
      stack.cards.findIndex((c) => c.id === card.id) + 1 === stack.cards.length &&
      card.order - 1 === game.draggingCards[0].order &&
      card.color !== game.draggingCards[0].color
    );
  },
};

const FREECELL = {
  name: "Free Cell",
  width: 1325,
  gameSetup: (config) => {
    const cards = getShuffledArr(config.cards);
    while (cards.length) {
      for (let i = 1; i <= 8; i++) {
        if (!cards.length) break;
        const stack = config.stacks.find((s) => s.id === `play${i}`);
        const card = cards.pop();
        card.direction = "up";
        stack.cards.push(card);
      }
    }
  },
  decks: [
    {
      id: "standard",
      width: WIDTH,
      height: HEIGHT,
    },
  ],
  cards: CARDS,
  stacks: [
    {
      id: "free1",
      type: "free",
      x: getX(0),
      y: getY(0),
      ...FREE_STACK,
    },
    {
      id: "free2",
      type: "free",
      x: getX(1),
      y: getY(0),
      ...FREE_STACK,
    },
    {
      id: "free3",
      type: "free",
      x: getX(2),
      y: getY(0),
      ...FREE_STACK,
    },
    {
      id: "free4",
      type: "free",
      x: getX(3),
      y: getY(0),
      ...FREE_STACK,
    },
    {
      id: "aces1",
      type: "aces",
      x: getX(5),
      y: getY(0),
      ...ACES_STACK,
    },
    {
      id: "aces2",
      type: "aces",
      x: getX(6),
      y: getY(0),
      ...ACES_STACK,
    },
    {
      id: "aces3",
      type: "aces",
      x: getX(7),
      y: getY(0),
      ...ACES_STACK,
    },
    {
      id: "aces4",
      type: "aces",
      x: getX(8),
      y: getY(0),
      ...ACES_STACK,
    },
    {
      id: "play1",
      type: "play",
      x: getX(0.5),
      y: getY(1),
      ...PLAY_STACK,
    },
    {
      id: "play2",
      type: "play",
      x: getX(1.5),
      y: getY(1),
      ...PLAY_STACK,
    },
    {
      id: "play3",
      type: "play",
      x: getX(2.5),
      y: getY(1),
      ...PLAY_STACK,
    },
    {
      id: "play4",
      type: "play",
      x: getX(3.5),
      y: getY(1),
      ...PLAY_STACK,
    },
    {
      id: "play5",
      type: "play",
      x: getX(4.5),
      y: getY(1),
      ...PLAY_STACK,
    },
    {
      id: "play6",
      type: "play",
      x: getX(5.5),
      y: getY(1),
      ...PLAY_STACK,
    },
    {
      id: "play7",
      type: "play",
      x: getX(6.5),
      y: getY(1),
      ...PLAY_STACK,
    },
    {
      id: "play8",
      type: "play",
      x: getX(7.5),
      y: getY(1),
      ...PLAY_STACK,
    },
  ],
};

export default FREECELL;
